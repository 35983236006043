// ページ内アンカーリンクのスクロールエフェクト
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定)
jQuery(function ($) {
	var headerHeight = 0; // ヘッダー追従でない場合
	// var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合
	$('a[href^="#"]').click(function () {
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		if (target.length > 0) {
			var position = target.offset().top - headerHeight;
			$("html, body").animate({ scrollTop: position }, 550, "swing");
			return false;
		}
	});
})

// 追従CVの表示
jQuery(function ($) {
	if ($('#cv_fixed').length > 0) {
		var cvFixed = $('#cv_fixed');
		cvFixed.css("display", "none");

		// スクロールが100に達したらボタン表示
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				cvFixed.fadeIn();
			} else {
				cvFixed.fadeOut();
			}

			// // 1番下に達したらfadeOut
			// if ($(this).scrollTop() + $(this).height() == $(document).height()) {
			// 	cvFixed.fadeOut();
			// }
		});

		// // スムーススクロール
		// cvFixed.click(function () {
		// 	$('#contact').animate({
		// 		scrollTop: 0
		// 	}, 500);
		// 	return false;
		// });
	}
});


// ヘッダーの固定エフェクト
// jQuery(function($) {
// 	var $header = $('header');
// 	$(window).scroll(function() {
// 		if ($(window).scrollTop() > 50) {
// 			$header.addClass('fixed');
// 		} else {
// 			$header.removeClass('fixed');
// 		}
// 	});
// });


// スクロールヒント
jQuery(function ($) {
	new ScrollHint('.js-scrollable', {
		scrollHintIconAppendClass: 'scroll-hint-icon-white',//背景白
		i18n: {
			scrollable: "スクロールできます"
		},
	});

});
